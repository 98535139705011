const isDevelopment: boolean =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";

export const API_PATH = isDevelopment
  ? "http://localhost:8222"
  : // ? "https://b.auth.dev.cryptoknights.games"
    // : // ? "https://b.auth.dev.cryptoknights.games"
    "https://auth.dev.cryptoknights.games";
export const CHAIN_ID = isDevelopment ? "13131313" : "80001";

export const INVENTORY_PATH = isDevelopment
  ? //  "https://inventory.dev.cryptoknights.games"
    "http://localhost:8333"
  : // ? "https://inventory.dev.cryptoknights.games"
    "https://inventory.dev.cryptoknights.games";

export const BRIDGE_PATH = isDevelopment
  ? // ? // "https://ck-bridge.dev.cryptoknights.games"
    "http://127.0.0.1:8088"
  : "https://ck-bridge.dev.cryptoknights.games";

export const DAI_CONTRACT = isDevelopment
? 
  "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0"
: "0xF25B58B4eb2F1454c8ba4c64C5faB696F8De9ee1";

export const KNIGHTMINTER_CONTRACT = isDevelopment
? 
  "0x0B306BF915C4d645ff596e518fAf3F9669b97016"
: "0xFB69Ee00cF44091A8046a324EFa87e86547212cd";

export const STAKED_RUBY = isDevelopment
? 
  "0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE"
: "0xaEE724aB04432545B1BAaAF004DA586ef50F71cD";
